import React, { useEffect } from "react";
import classes from "./Dashboard.module.css";
import { useStores } from "../../store/StoresIndex";
import Toolbar from "../toolbar/Toolbar";
import { Routes, Route } from "react-router-dom";
import ParameterPanel from "./parameter/ParameterPanel";
import ChangePwdPanel from "./password/ChangePwdPanel";
import HistoricalDataPanel from "./historicaldata/HistoricalDataPanel";
import Results from "../result/Results";

const Dashboard = () => {
  const { InputStore } = useStores();
  const { getUserInputData } = InputStore;

  // when accessing this page, update data
  useEffect(() => {
    getUserInputData();
  }, []);

  return (
    <div>
      <Toolbar />
      <div className={classes.tab_panel}>
        <Routes>
          <Route path="/" element={<ParameterPanel />} />
          <Route path="edit-parameters" element={<ParameterPanel />} />
          <Route path="check-data" element={<HistoricalDataPanel />} />
          <Route path="change-password" element={<ChangePwdPanel />} />
          <Route path="check-data/details" element={<Results />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
