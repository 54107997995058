import { Space, Button, Table } from "antd";
import { useStores } from "../../../store/StoresIndex";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import ExcelJS from "exceljs";

const HistoricalDataPanel = () => {
  const { InputStore } = useStores();
  const { allInputData, displayedData } = InputStore;
  const navigate = useNavigate();
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("user_input_data");

  // download the from front-end as the data already be read and store in Mobx
  const downLoadData = () => {
    // column name
    let columnName = [];
    for (let i in allInputData[0]) {
      let tempObj = { name: "" };
      tempObj.name = i;
      columnName.push(tempObj);
    }

    sheet.addTable({
      name: `Header`,
      ref: "A1", // header's information starts from A1
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "",
        showRowStripes: false,
        showFirstColumn: true,
        width: 200,
      },
      columns: [
        { name: "Notes" },
        // { name: "probability" },
        // { name: "rank" },
      ], // if pass multiple arrays, it will continuely draw in B1, C3...
      rows: [
        [`yestimate: means estimate probability of stress from users`],
        [`probability: means actual probability of stress from the calculator`],
        [`rank: means actual rank of stress from the calculator`],
      ],
    });

    // set the data section
    let headerName = "InputData";
    sheet.addTable({
      name: headerName,
      ref: "A6", // data start to display from A5
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        width: 200,
      },
      columns: columnName ? columnName : [{ name: "" }], //pass the column's name
      rows: allInputData.map((e) => {
        let arr = [];
        for (let i in e) {
          arr.push(e[i]);
        }
        return arr;
      }),
    });

    // set the font's format of target cell
    // sheet.getCell("A2").font = { size: 16, bold: true };
    // sheet.getCell("B2").font = { size: 16, bold: true };
    // sheet.getCell("C2").font = { size: 16, bold: true };

    // set the width of target column
    sheet.columns = sheet.columns.map((e) => {
      const expr = e.values[6];
      switch (expr) {
        case "age":
          return { width: 50 };
        case "family":
          return { width: 30 };
        case "date_time":
          return { width: 30 };
        case "labour":
          return { width: 25 };
        case "yestimate":
          return { width: 15 };
        case "probability":
          return { width: 15 };
        case "tenure":
          return { width: 20 };
        default:
          return { width: 10 };
      }
    });

    const table = sheet.getTable(headerName);
    for (let i = 0; i < table.table.columns.length; i++) {
      // set column name's formate
      sheet.getCell(`${String.fromCharCode(65 + i)}6`).font = { size: 14 };
      sheet.getCell(`${String.fromCharCode(65 + i)}6`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "c5d9f1" },
      };

      // setting the format of data section
      for (let j = 0; j < table.table.rows.length; j++) {
        let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 6}`);
        rowCell.alignment = { wrapText: true };
        rowCell.border = {
          bottom: {
            style: "thin",
            color: { argb: "a6a6a6" },
          },
        };
      }
    }
    table.commit();

    // set download method
    const writeFile = (fileName, content) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    // export to a xlsx file
    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile("Input Data.xlsx", buffer);
    });
  };
  /**
   * base on current id to find the detail of data
   *
   * @param {*} id: current data's id
   */
  const showDetail = (id) => {
    let sourceData = allInputData.find((item) => item.id === id);
    sourceData.source = "data";
    navigate("/dashboard/check-data/details", {
      state: toJS(sourceData),
    });
  };

  // table's format
  const columns = [
    {
      title: "Estimate Probability",
      dataIndex: "estimate",
      key: "estimate",
      align: "center",
    },
    {
      title: "Actual Probability",
      dataIndex: "actual",
      key: "actual",
      align: "center",
    },
    {
      title: "Actual Rank",
      dataIndex: "rank",
      key: "rank",
      align: "center",
      sorter: (a, b) => a.rank.length - b.rank.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      sorter: (a, b) =>
        Date.parse(a.date.replace("-", "/").replace("-", "/")) -
        Date.parse(b.date.replace("-", "/").replace("-", "/")),
    },
    {
      title: "View",
      dataIndex: "action",
      key: "action",
      render: (_, record, index) => (
        <>
          <Space size="middle">
            <Button
              type="primary"
              shape="round"
              onClick={() => showDetail(record.key)}
            >
              <SearchOutlined />
            </Button>
          </Space>
        </>
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={displayedData}
        columns={columns}
        rowClassName={(_, index) => {
          let className = "odd-row";
          if (index % 2 === 1) className = "even-row";
          return className;
        }}
      />
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        size="large"
        style={{
          position: "relative",
          float: "left",
          top: "-50px",
        }}
        onClick={downLoadData}
        disabled={displayedData.length === 0}
      >
        Download
      </Button>
    </div>
  );
};

export default observer(HistoricalDataPanel);
