import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import API_URL from "../utils/Url";
import { message } from "antd";
import base64 from "base-64";

class RegressionShop {
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * The following four variables look weird,
   * because I need a unified format for variables that carry data from DB
   * this format just only for assigning data from DB
   */
  intercept = {
    0: { value: 0 },
  };
  lndispincome = {
    0: { value: 0 },
  };
  lnwealth = {
    0: { value: 0 },
  };
  lnhcostsh = {
    0: { value: 0 },
  };

  hied = {
    1: { label: "Yes", value: 0 },
    2: { label: "No", value: 0 },
  };

  gender = {
    1: { label: "Yes", value: 0 },
    2: { label: "No", value: 0 },
  };

  disability = {
    1: { label: "Yes", value: 0 },
    2: { label: "No", value: 0 },
  };

  numberOfAdults = {
    9: { label: 1, value: 0 },
    2: { label: 2, value: 0 },
    3: { label: 3, value: 0 },
    4: { label: 4, value: 0 },
  };

  numberOfChildren = {
    1: { label: 1, value: 0 },
    2: { label: 2, value: 0 },
    3: { label: 3, value: 0 },
    9: { label: 4, value: 0 },
    0: { label: 0, value: 0 },
  };

  /**
   *  according to the user's input to select target value
   */
  statuesOfLabourForce = {
    1.25: { label: "Full time", value: 0 },
    1.75: { label: "Part time", value: 0 },
    2: { label: "Unemployed", value: 0 },
    3: { label: "Not in the labour force", value: 0 },
  };

  ageOfHousehold = {
    35: { label: "<35 years", value: 0 },
    50: { label: "35 to 49 years", value: 0 },
    65: { label: "50 to 64 years", value: 0 },
    75: { label: "65 to 74 years", value: 0 },
    85: { label: "75 plus", value: 0 },
  };

  familyType = {
    1: { label: "Couple with kids", value: 0 },
    2: { label: "Single parent with kids", value: 0 },
    3: { label: "Couple only", value: 0 },
    4: { label: "Lone person", value: 0 },
    5: { label: "Group, multiple family, or other", value: 0 },
  };

  regionOfResidence = {
    1: { label: "NSW", value: 0 },
    2: { label: "VIC", value: 0 },
    3: { label: "QLD", value: 0 },
    4: { label: "SA", value: 0 },
    5: { label: "WA", value: 0 },
    6: { label: "TAS", value: 0 },
    7: { label: "NT", value: 0 },
    8: { label: "ACT", value: 0 },
  };

  tenureType = {
    1: { label: "Own Outright", value: 0 },
    2: { label: "Mortgage", value: 0 },
    3: { label: "Renter", value: 0 },
  };

  /**
   *  the values come from the research
   */
  wealthMulAge = {
    35: { label: "35", value: 0 },
    50: { label: "50", value: 0 },
    65: { label: "65", value: 0 },
    75: { label: "75", value: 0 },
    85: { label: "85", value: 0 },
  };

  LndispincomeAge = {
    35: { label: "35", value: 0 },
    50: { label: "50", value: 0 },
    65: { label: "65", value: 0 },
    75: { label: "75", value: 0 },
    85: { label: "85", value: 0 },
  };

  getDataFromDB = () => {
    axios
      .get(API_URL.regression)
      .then((res) => {
        // decode the base64 string first
        let base64Data = base64.decode(res.data);
        let tempData = JSON.parse(base64Data);
        // console.log(tempData);
        // the data is a array, so assign the data according to the parameter name from DB
        tempData.find((item) => {
          // async communication should upate the local state in runInAction
          runInAction(() => {
            let temp = this.matchToProperties(item.parameter);
            temp[item.number].value = item.estimate;
          });
          return null;
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  updateEstimateData = (newValue, parameter, number) => {
    // prepare a
    let sendData = {
      newValue: newValue,
      parameter: parameter,
      number: number,
    };

    // update local state then send a request to server
    runInAction(() => {
      let temp = this.matchToProperties(parameter);
      temp[number].value = newValue;
    });

    axios.post(API_URL.regressionValue, sendData).then((res) => {
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    });
  };

  // a useful way to find the matched property
  matchToProperties = (propName) => {
    switch (propName) {
      case "Intercept":
        return this.intercept;
      case "Lndispincome":
        return this.lndispincome;
      case "lnwealth":
        return this.lnwealth;
      case "adults_":
        return this.numberOfAdults;
      case "kids_":
        return this.numberOfChildren;
      case "HHLFS":
        return this.statuesOfLabourForce;
      case "age":
        return this.ageOfHousehold;
      case "STATEHEC":
        return this.regionOfResidence;
      case "famHH":
        return this.familyType;
      case "TENURECF":
        return this.tenureType;
      case "lnwealth*age":
        return this.wealthMulAge;
      case "Lndispincome*age":
        return this.LndispincomeAge;
      case "lnhcostsh":
        return this.lnhcostsh;
      case "hied":
        return this.hied;
      case "SEXRH":
        return this.gender;
      case "DISBHH1":
        return this.disability;
      default:
        return null;
    }
  };
}

const RegressionStore = new RegressionShop();
export default RegressionStore;
