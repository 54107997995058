import { makeAutoObservable, runInAction } from "mobx";
import API_URL from "../utils/Url";
import axios from "axios";
import { message } from "antd";

class Inputs {
  constructor() {
    makeAutoObservable(this);
  }

  // the format of this variable should be an array
  allInputData = null;
  // data that display to the Professor
  displayedData = [];

  // store user's input if they agree
  storeUserInputData = (userInputs) => {
    axios.post(API_URL.inputsData, userInputs).then((res) => {
      if (res.status !== 200) {
        message.error(res.data.message);
      }
    });
  };

  // get user's input
  getUserInputData = async () => {
    await axios.get(API_URL.inputs).then((res) => {
      // because the data comes from DB directly
      // so we need to clear local variable to avoid there are duplicate data
      if (res.status === 200) {
        runInAction(() => {
          this.displayedData = [];
          // assign to a variable first
          this.allInputData = res.data;
          let tempData = res.data;
          tempData.map((item) => {
            this.displayedData.push({
              key: item.id,
              estimate: item.yestimate,
              actual: item.probability,
              rank: item.rank,
              date: item.date_time,
            });
            return null;
          });
          // arrange data
        });
      } else {
        message.error(res.data.message);
      }
    });
  };
}

const InputStore = new Inputs();
export default InputStore;
