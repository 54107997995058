import React from "react";
import { configure } from "mobx";
import RegressionStore from "./RegressionStore";
import UserStore from "./UserStore";
import InputStore from "./InputStore";

configure({ enforceActions: "always" });
const stores = { RegressionStore, UserStore, InputStore };
const CounterContext = React.createContext(stores);
export const useStores = () => React.useContext(CounterContext);
