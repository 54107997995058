import {
  Button,
  Form,
  Select,
  Input,
  Row,
  Col,
  Checkbox,
  Popover,
  Radio,
} from "antd";
import React from "react";
import { observer } from "mobx-react";
import RANK_LIST from "../../utils/Constant";
import { useStores } from "../../store/StoresIndex";
import { Link, useNavigate } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import UC_logo from "../../assets/uclogo_big.png";
import CSRM_logo from "../../assets/CSRM_logo.png";
import Classes from "./Calculator.module.css";
import { useEffect } from "react";

const { Option } = Select;

// setting col space for label and wrapper in the form
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 14 },
    lg: { span: 13 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 10 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
};

/**
 *  This is a calculator responsible for calculating the estimate probability of financial stress
 *  according to what user input
 */
const Calculator = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { RegressionStore, InputStore } = useStores();
  const {
    intercept,
    lndispincome,
    lnwealth,
    numberOfAdults,
    numberOfChildren,
    statuesOfLabourForce,
    ageOfHousehold,
    familyType,
    regionOfResidence,
    tenureType,
    lnhcostsh,
    wealthMulAge,
    LndispincomeAge,
    hied,
    gender,
    disability,
  } = RegressionStore;
  const { storeUserInputData } = InputStore;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * It will be called when users click 'calculate' button
   * @param {*} userInput: form's value
   */
  const onFinish = (userInput) => {
    // for testing
    // console.log("Received values of form: ", userInput);

    // console.log("intercept", intercept[0].value);
    // console.log("wealthMulAge", wealthMulAge[35].value);
    // console.log("numberOfAdults", numberOfAdults[userInput.adults].value);
    // console.log("numberOfChildren", numberOfChildren[userInput.children].value);
    // console.log(
    //   "statuesOfLabourForce",
    //   statuesOfLabourForce[userInput.labour].value
    // );
    // console.log("ageOfHousehold", calSumData(userInput.age, ageOfHousehold));
    // console.log("familyType", familyType[userInput.family].value);
    // console.log("regionOfResidence", regionOfResidence[userInput.region].value);
    // console.log("tenureType", tenureType[userInput.tenure].value);
    // console.log("lnhcostsh", lnhcostsh[0].value);
    // console.log("wealthMulAge", calSumData(userInput.age, wealthMulAge));
    // console.log("hied", hied[userInput.degree]);
    // console.log("gender", gender[userInput.gender]);

    // console.log("Income: ", parseFloatToLog(userInput.income));
    // console.log("Wealth: ", parseFloatToLog(userInput.wealth));

    // the formula to calculate the probaility Of financial stress
    let tempResult =
      intercept[0].value +
      parseFloatToLog(userInput.income) * lndispincome[0].value +
      parseFloatToLog(userInput.wealth) * lnwealth[0].value +
      numberOfAdults[userInput.adults].value +
      numberOfChildren[userInput.children].value +
      statuesOfLabourForce[userInput.labour].value +
      calSumData(userInput.age, ageOfHousehold) +
      familyType[userInput.family].value +
      regionOfResidence[userInput.region].value +
      tenureType[userInput.tenure].value +
      parseFloatToLog(userInput.costs) * lnhcostsh[0].value +
      parseFloatToLog(userInput.income) *
        calSumData(userInput.age, LndispincomeAge) +
      parseFloatToLog(userInput.wealth) *
        calSumData(userInput.age, wealthMulAge) +
      hied[userInput.degree].value +
      gender[userInput.gender].value +
      disability[userInput.disability].value;

    // to keep three decimal
    let finalProbability = (
      Math.exp(tempResult) /
      (1 + Math.exp(tempResult))
    ).toFixed(3);

    // console.log("final", finalProbability);
    // find the actual rank of financial stress
    let finalRank = null;
    if (finalProbability >= 1) {
      finalRank = 1;
    } else {
      finalRank = findFinancialWellbeingRank(finalProbability);
    }

    let ageGroup = assembleAgeGroup(userInput.age, ageOfHousehold);

    // prepare a summary dataset to send to result page
    let summaryData = {
      income: userInput.income,
      wealth: userInput.wealth,
      adults: numberOfAdults[userInput.adults].label,
      children: numberOfChildren[userInput.children].label,
      labour: statuesOfLabourForce[userInput.labour].label,
      age: ageGroup,
      family: familyType[userInput.family].label,
      region: regionOfResidence[userInput.region].label,
      tenure: tenureType[userInput.tenure].label,
      costs: userInput.costs,
      degree: hied[userInput.degree].label,
      gender: gender[userInput.gender].label,
      disability: disability[userInput.disability].label,
      yestimate: userInput.y_estimate,
      probability: finalProbability,
      rank: finalRank,
    };

    //if users agree to store their input data, then send the data to the server
    if (userInput.agreement) {
      storeUserInputData(summaryData);
    }

    navigate("/results", { state: summaryData });
    onReset();
  };

  // to create a string to display multiple age if users select
  const assembleAgeGroup = (ageArray, parameterArray) => {
    let tempAgeGroup = "";
    let index = 1;

    for (let age of ageArray) {
      tempAgeGroup += `${index}. (` + parameterArray[age].label + "); ";
      index += 1;
    }

    return tempAgeGroup;
  };

  //According to a table to calculate the actual rank of financial wellbeing
  const findFinancialWellbeingRank = (tempProbability) => {
    let temp = RANK_LIST.find((item) => item.probility > tempProbability);
    let tempResult = temp.rank + 1;
    return tempResult;
  };

  // convert userinput to a logarithm
  const parseFloatToLog = (userInput) => {
    return Math.log(parseFloat(userInput));
  };

  // calculate total value according to different parameter and its value
  const calSumData = (ageArray, parameterArray) => {
    let tempResult = 0;
    for (let age of ageArray) {
      tempResult += parameterArray[age].value;
    }

    return tempResult;
  };

  /**
   * It be called when users click 'reset' button
   */
  const onReset = () => {
    form.resetFields();
  };

  const content_income = (
    <div style={{ width: "500px", height: "90px" }}>
      <p>
        Income from all sources after personal income tax including wages and
        salaries, bonuses, superannuation income, business income, welfare
        payments, dividends, bank interest, rental property income.
      </p>
    </div>
  );

  /**
   *  the note of wealth
   */
  const content_wealth = (
    <div style={{ width: "500px", height: "90px" }}>
      <p>
        Include value of principal place of residence, any investment
        properties,savings accounts and term deposits, shares, superannuation
        balance, jewellery, furniture, cars, boats etc. Deduct any liabilities
        including home mortgage, credit card, investment property loans etc
      </p>
    </div>
  );

  /**
   *  the note of adults
   */
  const content_adults = (
    <div style={{ width: "470px", height: "50px" }}>
      <p>
        Adults are any person who usually lives at your house who are aged over
        15 years
      </p>
    </div>
  );

  /**
   *  the note of age
   */
  const content_age = (
    <div style={{ width: "420px", height: "25px" }}>
      <p>Head of the household is the person who has the highest income</p>
    </div>
  );

  /**
   *  the note of housing cost
   */
  const content_cost = (
    <div style={{ width: "420px", height: "50px", padding: "1%" }}>
      <p>
        Including your regular mortgage repayments or rent payments, council
        general and water rates, body corporate fees
      </p>
    </div>
  );

  /**
   *  the note of disability
   */
  const content_disability = (
    <div style={{ width: "500px", height: "300px", padding: "3%" }}>
      <p>
        Note: Inlcude any long term health conditions such as shortness of
        breath, chronic or recurring pain, nervous or emotional condition, long
        term effects of a stroke, head injury or other brain damage, any other
        long term condition that requires treatment or medication, any other
        condition such as arthritis, asthma, heart disease, alzheimer's disease,
        dementia etc. Also include physical disabilities such as sight problems
        (not corrected by glasses/contact lenses), hearing problems, speech
        problems, blackouts, fits or loss of consciousness, leaning
        difficulties, limited use of arms or fingers, difficulty gripping
        things, limited use of feet or legs, any condition that limits physical
        activity or physical work (eg back problems, migraines), any
        disfigurement or deformity, any mental illness for which help or
        supervision is required
      </p>
    </div>
  );

  const logoColStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div
      style={{
        margin: "0",
        minHeight: "100%",
        padding: "3%",
        textAlign: "left",
        background: "rgba(24, 144, 255, 0.05)",
      }}
    >
      {/* logo and title section */}
      <div
        style={{
          textAlign: "center",
          paddingBottom: "20px",
        }}
      >
        <Row>
          <Col span={8} style={logoColStyle}>
            <img
              className={Classes.UC_Logo}
              src={UC_logo}
              alt="Uniting Care logo"
            />
          </Col>
          <Col span={8}>
            <h1 className={Classes.MainTitle}>
              Financial Wellbeing Calculator
            </h1>
            <div className={Classes.SubTitle}>
              Please complete the form to calculate your household’s financial
              wellbeing ranking in Australia
            </div>

            <Link to="/about">
              <span className={Classes.AboutLink}>About the calculator</span>
            </Link>
          </Col>
          <Col span={8} style={logoColStyle}>
            <img
              src={CSRM_logo}
              alt="CSRM logo"
              className={Classes.CSRM_Logo}
            />
          </Col>
        </Row>
      </div>
      {/* A form for users to input their data */}
      <Form
        name="financial-stress-calculator"
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
        style={{ background: "#fff", padding: "20px" }}
      >
        <Form.Item
          name="income"
          label="Disposable Income of Household ($pw) "
          rules={[
            {
              required: true,
              message: "Please enter a disposable income of household",
            },
            {
              type: "number",
              message: "please select a positive number",
              transform(value) {
                if (value) {
                  // transfer input value from string to number
                  return Number(value);
                }
              },
              min: 1,
            },
          ]}
          tooltip={{
            icon: (
              <Popover content={content_income} trigger="click">
                <QuestionCircleOutlined />{" "}
              </Popover>
            ),
          }}
        >
          <Input placeholder="$pw (after-tax, incl retiree super income) " />
        </Form.Item>

        <Form.Item
          name="wealth"
          label="Total households net wealth (Assets - liabilities) "
          tooltip={{
            icon: (
              <Popover content={content_wealth} trigger="click">
                <QuestionCircleOutlined />{" "}
              </Popover>
            ),
          }}
          rules={[
            {
              required: true,
              message: "Please enter a total wealth of household",
            },
            {
              type: "number",
              message: "please select a positive number",
              transform(value) {
                if (value) {
                  // transfer input value from string to number
                  return Number(value);
                }
              },
              min: 1,
            },
          ]}
        >
          <Input placeholder="Net wealth" />
        </Form.Item>

        <Form.Item
          name="adults"
          label="Number of adults in households"
          tooltip={{
            icon: (
              <Popover content={content_adults} trigger="click">
                <QuestionCircleOutlined />{" "}
              </Popover>
            ),
          }}
          rules={[
            {
              required: true,
              message: "Please select the number of adults in households",
            },
          ]}
        >
          <Select placeholder="please select a positive number">
            <Option value="9">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="children"
          label="Number of children 15 years and under"
          hasFeedback
          rules={[
            {
              required: true,
              message:
                "Please select the number of children 15 years and under",
            },
          ]}
        >
          <Select placeholder="Please select a number">
            <Option value="0">0</Option>
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="9">4</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="labour"
          label="Labour Force Status of highest income earner"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please select the labour force status",
            },
          ]}
        >
          <Select placeholder="Please select a status">
            <Option value="1.25">Full time</Option>
            <Option value="1.75">Part time</Option>
            <Option value="2">
              Unemployed (looking for work but not employed)
            </Option>
            <Option value="3">Not in the labour force</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="age"
          label="Age of head of the household"
          tooltip={{
            icon: (
              <Popover content={content_age} trigger="click">
                <QuestionCircleOutlined />{" "}
              </Popover>
            ),
          }}
          rules={[
            {
              required: true,
              message: "Please select the age",
            },
          ]}
        >
          <Checkbox.Group>
            <Row>
              <Col span={8}>
                <Checkbox value="35" style={{ lineHeight: "28px" }}>
                  {"< 35 years"}
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="50" style={{ lineHeight: "28px" }}>
                  35 to 49 years
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="65" style={{ lineHeight: "28px" }}>
                  50 to 64 years
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="75" style={{ lineHeight: "28px" }}>
                  65 to 74 years
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="85" style={{ lineHeight: "28px" }}>
                  75 plus
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          name="family"
          label="Family/Household Type"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please select a family/household type",
            },
          ]}
        >
          <Select placeholder="Please select a family type">
            <Option value="1">Couple with kids</Option>
            <Option value="2">Single parent with kids</Option>
            <Option value="3">Couple only</Option>
            <Option value="4">Lone person</Option>
            <Option value="5">Group, multiple family, or other</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="region"
          label="State of residence"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please select a state",
            },
          ]}
        >
          <Select placeholder="Please select an region">
            <Option value="1">NSW</Option>
            <Option value="2">VIC</Option>
            <Option value="3">QLD</Option>
            <Option value="4">SA</Option>
            <Option value="5">WA</Option>
            <Option value="6">TAS</Option>
            <Option value="7">NT</Option>
            <Option value="8">ACT</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="tenure"
          label="Tenure Type "
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please select a tenure type",
            },
          ]}
        >
          <Select placeholder="Please select a tenure type">
            <Option value="1"> Own Outright</Option>
            <Option value="2"> Mortgage</Option>
            <Option value="3"> Renter</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="degree"
          label="Degree or higher (Head of Household)"
          rules={[
            {
              required: true,
              message: "Please select a value",
            },
          ]}
        >
          <Radio.Group>
            <Radio value="1">Yes</Radio>
            <Radio value="2">No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="gender"
          label="Male head of household"
          rules={[
            {
              required: true,
              message: "Please select a value",
            },
          ]}
        >
          <Radio.Group>
            <Radio value="1">Yes</Radio>
            <Radio value="2">No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="disability"
          className={Classes.DisableText}
          label="Any household member have a disability or long term health condition"
          tooltip={{
            icon: (
              <Popover content={content_disability} trigger="click">
                <QuestionCircleOutlined />{" "}
              </Popover>
            ),
          }}
          rules={[
            {
              required: true,
              message: "Please select a value",
            },
          ]}
        >
          <Radio.Group className={Classes.DisableRadioText}>
            <Radio value="1">Yes</Radio>
            <Radio value="2">No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="costs"
          label="Regular housing costs ($pw)"
          tooltip={{
            icon: (
              <Popover content={content_cost} trigger="click">
                <QuestionCircleOutlined />{" "}
              </Popover>
            ),
          }}
          rules={[
            { required: true, message: "Regular Hosing Costs are necessary" },
            {
              type: "number",
              transform(value) {
                if (value) {
                  // transfer input value from string to number
                  return Number(value);
                }
              },
              min: 1,
              message: "please select a positive number",
            },
          ]}
        >
          <Input placeholder="$pw (Including regular mortgage repayments, rent, rates, body corporate fees)" />
        </Form.Item>

        <Form.Item
          name="y_estimate"
          label="Estimate of financial wellbeing"
          rules={[
            { required: true, message: " Your estimate is required" },
            {
              type: "number",
              message: " please enter a valid number",
              transform(value) {
                if (value) {
                  // transfer input value from string to number
                  return Number(value);
                }
              },
              min: 1,
            },
            {
              type: "number",
              message: " please enter a valid number",
              transform(value) {
                if (value) {
                  // transfer input value from string to number
                  return Number(value);
                }
              },
              max: 100,
            },
          ]}
        >
          <Input placeholder="1-100 (1=lowest, 100=highest)" />
        </Form.Item>

        <Form.Item
          name="agreement"
          label=" "
          colon={false}
          valuePropName="checked"
        >
          <Checkbox>
            I agree to store the input data for academic research purposes.
          </Checkbox>
        </Form.Item>
        <Form.Item label="  " colon={false}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ marginTop: "15px", fontWeight: "bold" }}
          >
            Calculate
          </Button>
          <Button
            htmlType="button"
            type="primary"
            onClick={onReset}
            danger
            style={{
              marginLeft: "20px",
              marginTop: "15px",
              fontWeight: "bold",
            }}
            size="large"
          >
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(Calculator);
