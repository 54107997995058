import axios from "axios";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

// Intercept requests and bring tokens to all requests
axios.interceptors.request.use((request) => {
  const jwt_token = sessionStorage.getItem("token");
  if (jwt_token) {
    // the header have to start with this "Authorization"
    request.headers["Authorization"] = `Bearer ${jwt_token}`;
  }
  return request;
});

// middleware: intercept the response and report an error if the token is illegal
axios.interceptors.response.use(
  (response) => {
    const res = response;
    if (res.data.token) {
      sessionStorage.setItem("token", res.data.token);
    }
    return res;
  },
  (error) => {
    var errRes = undefined;

    if (error.response.data) {
      errRes = error.response.data;
    }
    // console.log("the error response:", error.response.status);
    if (error.response.status === 401) {
      sessionStorage.removeItem("token");

      history.push("/login");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 0);
    }
    return Promise.reject(errRes); // return a error information
  }
);

// const SERVER_API = "http://localhost:5001/api";
// use "/backend/api" because the front-end run in docker container, and use Nginx
const SERVER_API = "/backend/api";
const API_URL = {
  regression: SERVER_API + "/regression",
  regressionValue: SERVER_API + "/regression/value",
  login: SERVER_API + "/user/login",
  changePwd: SERVER_API + "/user/password",
  inputs: SERVER_API + "/inputs/users",
  inputsData: SERVER_API + "/inputs/data",
};

export default API_URL;
