import { Table, Result, Button, Row, Col } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Classes from "./Results.module.css";
import { useEffect } from "react";

const Results = () => {
  let location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //data comes from the Calculator page
  const resultsSource = [
    {
      key: "1",
      item: "1. Disposable Income of Household ($pw)",
      value: location.state !== null ? location.state.income : "",
    },
    {
      key: "2",
      item: "2. Total households net wealth (Assets - liabilities)",
      value: location.state !== null ? location.state.wealth : "",
    },
    {
      key: "3",
      item: "3. Number of adults in households",
      value: location.state !== null ? location.state.adults : "",
    },
    {
      key: "4",
      item: "4. Number of children 15 years and under",
      value: location.state !== null ? location.state.children : "",
    },
    {
      key: "5",
      item: "5. Labour Force Status of highest income earner",
      value: location.state !== null ? location.state.labour : "",
    },
    {
      key: "6",
      item: "6. Age of head of the household",
      value: location.state !== null ? location.state.age : "",
    },
    {
      key: "7",
      item: "7. Family Type",
      value: location.state !== null ? location.state.family : "",
    },
    {
      key: "8",
      item: "8. Region of residence",
      value: location.state !== null ? location.state.region : "",
    },
    {
      key: "9",
      item: "9. Tenure Type",
      value: location.state !== null ? location.state.tenure : "",
    },

    {
      key: "10",
      item: "10. Degree or higher (Head of Household)",
      value: location.state !== null ? location.state.degree : "",
    },
    {
      key: "11",
      item: "11. Male head of household?",
      value: location.state !== null ? location.state.gender : "",
    },
    {
      key: "12",
      item: "12. Any household member have a disability or long term health condition?",
      value: location.state !== null ? location.state.disability : "",
    },
    {
      key: "13",
      item: "13. Housing Costs ($pw Including regular mortgage repayments, rent, rates, body corporate fees)",
      value: location.state !== null ? location.state.costs : "",
    },
    {
      key: "14",
      item: "14. Estimate of financial wellbeing (1=lowest, 100=highest)",
      value: location.state !== null ? location.state.yestimate + "%" : "",
    },
    {
      key: "15",
      item: <b>Your Probability of Stress</b>,
      value: location.state !== null ? location.state.probability : "",
    },

    {
      key: "16",
      item: (
        <b>
          Actual financial wellbeing rank (1 is lowest rank and 100 is highest
          rank)
        </b>
      ),
      value: location.state !== null ? location.state.rank : "",
    },
  ];

  const hanlderClick = () => {
    location.state === null
      ? navigate("/")
      : location.state.source === "data"
      ? navigate("/dashboard/check-data")
      : navigate("/");
  };

  /**
   *  set table column's name and props
   */
  const columns = [
    { title: "Item", dataIndex: "item", key: "item" },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, _, index) =>
        index > 13 ? (
          <span style={{ fontWeight: "bold" }}>{text}</span>
        ) : (
          <span>{text}</span>
        ),
    },
  ];

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 2 }}
          md={{ span: 2 }}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
          xxl={{ span: 4 }}
        />
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20 }}
          md={{ span: 20 }}
          lg={{ span: 16 }}
          xl={{ span: 16 }}
          xxl={{ span: 16 }}
        >
          {location.state !== null ? (
            <>
              <h1 className={Classes.Title}>Summary of input data</h1>
              <div style={{ paddingBottom: "20px" }}>
                If any of the content is challenging for you please reach out
                for support. You may wish to contact Lifeline on{" "}
                <b> 13 11 14 </b>or the National Debt Helpline on{" "}
                <b> 1800 007 007</b>.{" "}
              </div>
              <Table
                bordered
                style={{ padding: "0 3%" }}
                dataSource={resultsSource}
                columns={columns}
                pagination={false}
                rowClassName={(record, index) => {
                  let className = "odd-row";
                  if (index % 2 === 1) className = "even-row";
                  return className;
                }}
              />
              <Button
                type="primary"
                onClick={hanlderClick}
                style={{ margin: "30px" }}
                size="large"
              >
                {location.state.source === "data" ? (
                  <b>Back</b>
                ) : (
                  <b>Test again</b>
                )}
              </Button>
            </>
          ) : (
            // if not any data, it will display this content
            <Result
              style={{
                marginTop: "20%",
              }}
              status="warning"
              title="No results, please go to the Calculator page and do the test"
              extra={
                <Button type="primary" key="result" onClick={hanlderClick}>
                  {location.state === null ? (
                    <b> Go Calculator Page</b>
                  ) : location.state.source === "data" ? (
                    <b>Back</b>
                  ) : (
                    <b> Go Calculator Page</b>
                  )}
                </Button>
              }
            />
          )}
        </Col>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 2 }}
          md={{ span: 2 }}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
          xxl={{ span: 4 }}
        />
      </Row>
    </div>
  );
};

export default Results;
