import React from "react";
import NavigationItems from "../navigationItems/NavigationItems";
import classes from "./Toolbar.module.css";
import { Row, Col } from "antd";
import Logo from "../logo/Logo";

const Toolbar = () => {
  return (
    <header className={classes.Toolbar}>
      <Row>
        <Col xxl={4} xl={4} lg={5} md={24} sm={24} xs={24}>
          <Logo />
        </Col>
        <Col xxl={20} xl={20} lg={19} md={0} sm={0} xs={0}>
          <nav>
            <NavigationItems />
          </nav>
        </Col>
      </Row>
    </header>
  );
};

export default Toolbar;
