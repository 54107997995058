//The data comes from Pr. Ben Phillips
const RANK_LIST = [
  { probility: 0, rank: 100 },
  { probility: 0.029, rank: 99 },
  { probility: 0.038, rank: 98 },
  { probility: 0.047, rank: 97 },
  { probility: 0.054, rank: 96 },
  { probility: 0.062, rank: 95 },
  { probility: 0.069, rank: 94 },
  { probility: 0.078, rank: 93 },
  { probility: 0.085, rank: 92 },
  { probility: 0.092, rank: 91 },
  { probility: 0.1, rank: 90 },
  { probility: 0.106, rank: 89 },
  { probility: 0.111, rank: 88 },
  { probility: 0.117, rank: 87 },
  { probility: 0.125, rank: 86 },
  { probility: 0.13, rank: 85 },
  { probility: 0.135, rank: 84 },
  { probility: 0.141, rank: 83 },
  { probility: 0.145, rank: 82 },
  { probility: 0.15, rank: 81 },
  { probility: 0.155, rank: 80 },
  { probility: 0.16, rank: 79 },
  { probility: 0.165, rank: 78 },
  { probility: 0.169, rank: 77 },
  { probility: 0.175, rank: 76 },
  { probility: 0.181, rank: 75 },
  { probility: 0.185, rank: 74 },
  { probility: 0.189, rank: 73 },
  { probility: 0.194, rank: 72 },
  { probility: 0.198, rank: 71 },
  { probility: 0.202, rank: 70 },
  { probility: 0.206, rank: 69 },
  { probility: 0.21, rank: 68 },
  { probility: 0.215, rank: 67 },
  { probility: 0.22, rank: 66 },
  { probility: 0.225, rank: 65 },
  { probility: 0.229, rank: 64 },
  { probility: 0.233, rank: 63 },
  { probility: 0.237, rank: 62 },
  { probility: 0.243, rank: 61 },
  { probility: 0.248, rank: 60 },
  { probility: 0.252, rank: 59 },
  { probility: 0.257, rank: 58 },
  { probility: 0.261, rank: 57 },
  { probility: 0.266, rank: 56 },
  { probility: 0.272, rank: 55 },
  { probility: 0.277, rank: 54 },
  { probility: 0.282, rank: 53 },
  { probility: 0.288, rank: 52 },
  { probility: 0.294, rank: 51 },
  { probility: 0.299, rank: 50 },
  { probility: 0.307, rank: 49 },
  { probility: 0.312, rank: 48 },
  { probility: 0.32, rank: 47 },
  { probility: 0.326, rank: 46 },
  { probility: 0.331, rank: 45 },
  { probility: 0.338, rank: 44 },
  { probility: 0.344, rank: 43 },
  { probility: 0.35, rank: 42 },
  { probility: 0.358, rank: 41 },
  { probility: 0.365, rank: 40 },
  { probility: 0.373, rank: 39 },
  { probility: 0.381, rank: 38 },
  { probility: 0.389, rank: 37 },
  { probility: 0.398, rank: 36 },
  { probility: 0.408, rank: 35 },
  { probility: 0.417, rank: 34 },
  { probility: 0.429, rank: 33 },
  { probility: 0.44, rank: 32 },
  { probility: 0.449, rank: 31 },
  { probility: 0.458, rank: 30 },
  { probility: 0.469, rank: 29 },
  { probility: 0.481, rank: 28 },
  { probility: 0.491, rank: 27 },
  { probility: 0.504, rank: 26 },
  { probility: 0.518, rank: 25 },
  { probility: 0.532, rank: 24 },
  { probility: 0.546, rank: 23 },
  { probility: 0.562, rank: 22 },
  { probility: 0.577, rank: 21 },
  { probility: 0.595, rank: 20 },
  { probility: 0.611, rank: 19 },
  { probility: 0.63, rank: 18 },
  { probility: 0.652, rank: 17 },
  { probility: 0.674, rank: 16 },
  { probility: 0.698, rank: 15 },
  { probility: 0.721, rank: 14 },
  { probility: 0.738, rank: 13 },
  { probility: 0.764, rank: 12 },
  { probility: 0.8, rank: 11 },
  { probility: 0.823, rank: 10 },
  { probility: 0.847, rank: 9 },
  { probility: 0.872, rank: 8 },
  { probility: 0.896, rank: 7 },
  { probility: 0.92, rank: 6 },
  { probility: 0.939, rank: 5 },
  { probility: 0.957, rank: 4 },
  { probility: 0.973, rank: 3 },
  { probility: 0.991, rank: 2 },
  { probility: 1.0, rank: 1 },
];

export default RANK_LIST;
// the data below is the first version of data for backing up. please don't delete

//  /**
//    *  those value for calculating the result
//    */
//   let intercept = 6.4107;
//   let lndispincome = -0.933;
//   let lnwealth = -0.2074;
//   let lnhcostsh = 0.0692;

//   const numberOfAdults = {
//     1: 0.562,
//     2: 1.0816,
//     3: 1.4826,
//     4: 0,
//   };

//   const numberOfChildren = {
//     1: 0.5239,
//     2: 0.6636,
//     3: 1.0016,
//     4: 0,
//     0: 0,
//   };

//   /**
//    *  according to the user's input to select target value
//    */
//   const statuesOfLabourForce = {
//     1: { label: "Full time", value: -0.5872 },
//     2: { label: "Part time", value: -0.438 },
//     3: { label: "Unemployed", value: 0.5624 },
//     4: { label: "Not in the labour force", value: 0 },
//   };

//   const ageOfHousehold = {
//     1: { label: "<35 years", value: 2.1603 },
//     2: { label: "35 to 49 years", value: 4.1504 },
//     3: { label: "50 to 64 years", value: 4.7017 },
//     4: { label: "65 to 74 years", value: 1.0743 },
//     5: { label: "75 plus", value: 0 },
//   };

//   const familyType = {
//     1: { label: "Couple with kids", value: -0.1949 },
//     2: { label: "Single parent with kids", value: 0.6367 },
//     3: { label: "Couple only", value: 0.1545 },
//     4: { label: "Lone person", value: 0.3924 },
//     5: { label: "Group, multiple family, or other", value: 0 },
//   };

//   const regionOfResidence = {
//     1: { label: "Capital City", value: 0.1365 },
//     2: {
//       label: "Regional area (including Canberra and Northern Territory)",
//       value: 0,
//     },
//   };

//   const tenureType = {
//     1: { label: "Own Outright", value: -0.444 },
//     2: { label: "Mortgage", value: 0.1549 },
//     3: { label: "Renter", value: 0 },
//   };

//   /**
//    *  the values come from the research
//    */
//   const wealthMulAge = {
//     1: { label: "35", value: -0.0889 },
//     2: { label: "50", value: -0.2367 },
//     3: { label: "65", value: -0.2621 },
//     4: { label: "75", value: -0.0301 },
//     5: { label: "85", value: 0 },
//   };
