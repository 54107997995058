import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../utils/Url";
import { message } from "antd";

class User {
  userEmail = null;
  constructor() {
    makeAutoObservable(this);
    this.userEmail = sessionStorage.getItem("email");
  }

  setUserEmail = (email) => {
    sessionStorage.setItem("email", email);
    this.userEmail = email;
  };

  logout = () => {
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("token");
  };

  changePwd = (newPwd) => {
    let sendData = {
      email: this.userEmail,
      newPassword: newPwd,
    };

    axios.post(API_URL.changePwd, sendData).then((res) => {
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    });
  };
}

const UserStore = new User();
export default UserStore;
