import React from "react";
import { Form, Input, Button } from "antd";
import classes from "./Login.module.css";
import { useStores } from "../../store/StoresIndex";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API_URL from "../../utils/Url";
import { message } from "antd";

const Login = () => {
  // const [showError, setShowError] = useState(false);
  const { UserStore } = useStores();
  const { setUserEmail } = UserStore;
  const navigate = useNavigate();
  const onFinish = (values) => {
    let sendData = {
      email: values.email,
      password: values.password,
    };

    axios.post(API_URL.login, sendData).then((res) => {
      if (res.status === 200) {
        setUserEmail(values.email);
        navigate("/dashboard");
      } else {
        message.error(res.data.message);
      }
    });
  };

  return (
    <div className={classes.signup_form}>
      <div style={{ minHeight: "120px", paddingLeft: "15%" }}>
        <h1>Login to Dashboard</h1>
      </div>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            { type: "email", message: "Please input a valid email!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            {
              min: 6,
              message: "The password needs at least 6 characters!",
            },
          ]}
        >
          <Input.Password autoComplete="off" />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button
            style={{
              width: "100%",
              border: "None",
            }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>

        {/* {showError ? (
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Alert
              message="Authentication Failed, the email may exist!"
              type="error"
              closable
              onClose={onClose}
            />
          </Form.Item>
        ) : null} */}
      </Form>
    </div>
  );
};

export default Login;
