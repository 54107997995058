import { Button, Divider } from "antd";
import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const SpaceLine = () => {
  return <div style={{ height: "10px" }} />;
};

const About = () => {
  return (
    <div style={{ padding: "3%" }}>
      <h1>About the calculator</h1>
      <div
        style={{
          textAlign: "left",
          margin: "0 20% 15% 20%",
          lineHeight: "32px",
        }}
      >
        <Divider />
        <div style={{ fontSize: "16px" }}>
          {" "}
          This app is designed to meaure financial wellbeing based on a range of
          factors that are expected to significantly impact your household's
          financial wellbeing. <SpaceLine /> The model is based on the
          probability (or chance) or a households being in some form of
          financial stress. This is calculated using a statistical technique
          known as logistic regression which associates a range of factors with
          financial stress. For the factors that you input below the model
          calculates a probabiltiy or chance that your household will face some
          level of financial stress. The regression model uses the unit records
          contained in the ABS Survey of Income and Housing for 2019-20{" "}
          <SpaceLine /> Financial stress is defined using a range of questions
          the ABS asks in the ABS Survey of Income and Housing (2019-20) such as
          whether your household can afford to pay the mortgage or rent on time,
          whether financial assistance has been sought from friends or welfare
          organisations or whether your household regularly spends more money
          than it earns. <SpaceLine /> The ABS survey asks a total of 14
          financial stress questions - responding to any one of these questions
          implies some form of financial stress for the houseohld. In the survey
          around one in three households responsed to at least one household
          stress question. Whe you input your various responses a probability is
          calculated of some form of financial stress in your household and this
          probability is converted to a ranking between 1 and 100. 1 implies
          your household's chance of financial stress is very high (top 1 per
          cent) and that your financial wellbeing ranking is 1. A score of 100
          implies your household's chance of stress is in the bottom 1 per cent
          and your ranking for financial wellbeing is 100. The model used to
          predict your risk of financial stress and therefore your financial
          wellbeing rank incorporates a range of factors that are associated
          with financial stress - such as income, wealth and age. <SpaceLine />{" "}
          There other factors that are not included such as smoking status or
          gambling habits or financial literacy or household consumption
          patterns that will also impact a household's financial stress and
          financial wellbeing. The model is therefore unable to include all
          possible factors and a high rank does not gaurantee that your
          household will not be in financial stress and a low rank does not
          gaurantee financial stress. The rank and financial stress
          probabilities are based on averages across all households. There is
          considerable variation in financial stress and financial wellbeing
          that the model is not able to account for. <SpaceLine /> The results
          of this app are therefore statistical averages and individual
          circumstances may mean that your actual financial wellbeing may vary
          from that predicted in this model.
          <Divider />
          The model parameters were developed by Associate Professor Ben
          Phillips of the ANU Centre for Social Research and Methods. <br />
          Any enquiries should be directed to <b>ben.phillips@anu.edu.au</b>
        </div>
        <Link to="/">
          <Button
            type="primary"
            style={{ float: "left", fontWeight: "bolder", marginTop: "10px" }}
          >
            <LeftOutlined />
            Back
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default About;
