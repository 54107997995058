import { useStores } from "../../../store/StoresIndex";
import { observer } from "mobx-react";
import { Tabs, Space, Button, Modal, Table, message } from "antd";
import classes from "./ParameterPanel.module.css";
import { useState, useRef } from "react";

const { TabPane } = Tabs;
// test whether the number is number
const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

const ParameterPanel = () => {
  const [currentValue, setCurrentValue] = useState(0);
  const [currentParameter, setCurrentParameter] = useState(null);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { RegressionStore } = useStores();
  const newValueRef = useRef();

  const {
    intercept,
    lndispincome,
    lnwealth,
    numberOfAdults,
    numberOfChildren,
    statuesOfLabourForce,
    ageOfHousehold,
    familyType,
    regionOfResidence,
    tenureType,
    LndispincomeAge,
    lnhcostsh,
    wealthMulAge,
    hied,
    gender,
    disability,
    updateEstimateData,
  } = RegressionStore;

  /**
   *  this is data source to the table
   *  name: Tab name
   *  content: the data that will be displayed on the table
   */

  const tabs = [
    {
      name: "Intercept",
      content: [
        {
          key: "1",
          parameter: "Intercept",
          number: "",
          estimate: intercept[0].value,
        },
      ],
    },
    {
      name: "Lndispincome",
      content: [
        {
          key: "1",
          parameter: "Lndispincome",
          number: "",
          estimate: lndispincome[0].value,
        },
      ],
    },
    {
      name: "lnwealth",
      content: [
        {
          key: "1",
          parameter: "lnwealth",
          number: "",
          estimate: lnwealth[0].value,
        },
      ],
    },
    {
      name: "adults_",
      content: [
        {
          key: "1",
          parameter: "adults_",
          number: "2",
          estimate: numberOfAdults[2].value,
        },
        {
          key: "2",
          parameter: "adults_",
          number: "3",
          estimate: numberOfAdults[3].value,
        },
        {
          key: "3",
          parameter: "adults_",
          number: "4",
          estimate: numberOfAdults[4].value,
        },
        {
          key: "4",
          parameter: "adults_",
          number: "9",
          estimate: numberOfAdults[9].value,
        },
      ],
    },
    {
      name: "kids_",
      content: [
        {
          key: "1",
          parameter: "kids_",
          number: "1",
          estimate: numberOfChildren[1].value,
        },
        {
          key: "2",
          parameter: "kids_",
          number: "2",
          estimate: numberOfChildren[2].value,
        },
        {
          key: "3",
          parameter: "kids_",
          number: "3",
          estimate: numberOfChildren[3].value,
        },
        {
          key: "4",
          parameter: "kids_",
          number: "9",
          estimate: numberOfChildren[9].value,
        },
      ],
    },
    {
      name: "HHLFS",
      content: [
        {
          key: "1",
          parameter: "HHLFS",
          number: "1.25",
          estimate: statuesOfLabourForce[1.25].value,
        },
        {
          key: "2",
          parameter: "HHLFS",
          number: "1.75",
          estimate: statuesOfLabourForce[1.75].value,
        },
        {
          key: "3",
          parameter: "HHLFS",
          number: "2",
          estimate: statuesOfLabourForce[2].value,
        },
        {
          key: "4",
          parameter: "HHLFS",
          number: "3",
          estimate: statuesOfLabourForce[3].value,
        },
      ],
    },
    {
      name: "age",
      content: [
        {
          key: "1",
          parameter: "age",
          number: "35",
          estimate: ageOfHousehold[35].value,
        },
        {
          key: "2",
          parameter: "age",
          number: "50",
          estimate: ageOfHousehold[50].value,
        },
        {
          key: "3",
          parameter: "age",
          number: "65",
          estimate: ageOfHousehold[65].value,
        },
        {
          key: "4",
          parameter: "age",
          number: "75",
          estimate: ageOfHousehold[75].value,
        },
        {
          key: "5",
          parameter: "age",
          number: "85",
          estimate: ageOfHousehold[85].value,
        },
      ],
    },
    {
      name: "famHH",
      content: [
        {
          key: "1",
          parameter: "famHH",
          number: "1",
          estimate: familyType[1].value,
        },
        {
          key: "2",
          parameter: "famHH",
          number: "2",
          estimate: familyType[2].value,
        },
        {
          key: "3",
          parameter: "famHH",
          number: "3",
          estimate: familyType[3].value,
        },
        {
          key: "4",
          parameter: "famHH",
          number: "4",
          estimate: familyType[4].value,
        },
        {
          key: "5",
          parameter: "famHH",
          number: "5",
          estimate: familyType[5].value,
        },
      ],
    },
    {
      name: "STATEHEC",
      content: [
        {
          key: "1",
          parameter: "STATEHEC",
          number: "1",
          estimate: regionOfResidence[1].value,
        },
        {
          key: "2",
          parameter: "STATEHEC",
          number: "2",
          estimate: regionOfResidence[2].value,
        },
        {
          key: "3",
          parameter: "STATEHEC",
          number: "3",
          estimate: regionOfResidence[3].value,
        },
        {
          key: "4",
          parameter: "STATEHEC",
          number: "4",
          estimate: regionOfResidence[4].value,
        },
        {
          key: "5",
          parameter: "STATEHEC",
          number: "5",
          estimate: regionOfResidence[5].value,
        },
        {
          key: "6",
          parameter: "STATEHEC",
          number: "6",
          estimate: regionOfResidence[6].value,
        },
        {
          key: "7",
          parameter: "STATEHEC",
          number: "7",
          estimate: regionOfResidence[7].value,
        },
        {
          key: "8",
          parameter: "STATEHEC",
          number: "8",
          estimate: regionOfResidence[8].value,
        },
      ],
    },
    {
      name: "TENURECF",
      content: [
        {
          key: "1",
          parameter: "TENURECF",
          number: "1",
          estimate: tenureType[1].value,
        },
        {
          key: "2",
          parameter: "TENURECF",
          number: "2",
          estimate: tenureType[2].value,
        },
        {
          key: "3",
          parameter: "TENURECF",
          number: "3",
          estimate: tenureType[3].value,
        },
      ],
    },
    {
      name: "Lndispincome*age",
      content: [
        {
          key: "1",
          parameter: "Lndispincome*age",
          number: "35",
          estimate: LndispincomeAge[35].value,
        },
        {
          key: "2",
          parameter: "Lndispincome*age",
          number: "50",
          estimate: LndispincomeAge[50].value,
        },
        {
          key: "3",
          parameter: "Lndispincome*age",
          number: "65",
          estimate: LndispincomeAge[65].value,
        },
        {
          key: "4",
          parameter: "Lndispincome*age",
          number: "75",
          estimate: LndispincomeAge[75].value,
        },
        {
          key: "5",
          parameter: "Lndispincome*age",
          number: "85",
          estimate: LndispincomeAge[85].value,
        },
      ],
    },
    {
      name: "lnwealth*age",
      content: [
        {
          key: "1",
          parameter: "lnwealth*age",
          number: "35",
          estimate: wealthMulAge[35].value,
        },
        {
          key: "2",
          parameter: "lnwealth*age",
          number: "50",
          estimate: wealthMulAge[50].value,
        },
        {
          key: "3",
          parameter: "lnwealth*age",
          number: "65",
          estimate: wealthMulAge[65].value,
        },
        {
          key: "4",
          parameter: "lnwealth*age",
          number: "75",
          estimate: wealthMulAge[75].value,
        },
        {
          key: "5",
          parameter: "lnwealth*age",
          number: "85",
          estimate: wealthMulAge[85].value,
        },
      ],
    },
    {
      name: "lnhcostsh",
      content: [
        {
          key: "1",
          parameter: "lnhcostsh",
          number: "",
          estimate: lnhcostsh[0].value,
        },
      ],
    },
    {
      name: "hied",
      content: [
        {
          key: "1",
          parameter: "hied",
          number: "1",
          estimate: hied[1].value,
        },
        {
          key: "2",
          parameter: "hied",
          number: "2",
          estimate: hied[2].value,
        },
      ],
    },
    {
      name: "SEXRH",
      content: [
        {
          key: "1",
          parameter: "SEXRH",
          number: "1",
          estimate: gender[1].value,
        },
        {
          key: "2",
          parameter: "SEXRH",
          number: "2",
          estimate: gender[2].value,
        },
      ],
    },
    {
      name: "DISBHH1",
      content: [
        {
          key: "1",
          parameter: "DISBHH1",
          number: "1",
          estimate: disability[1].value,
        },
        {
          key: "2",
          parameter: "DISBHH1",
          number: "2",
          estimate: disability[2].value,
        },
      ],
    },
  ];

  /**
   *  set table column's name and props
   */
  const columns = [
    { title: "Parameter", dataIndex: "parameter", key: "parameter" },
    { title: "Number", dataIndex: "number", key: "number" },
    { title: "Estimate", dataIndex: "estimate", key: "estimate" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Space size="middle">
            {/* <a>Edit {record.estimate}</a> */}
            <Button
              type="primary"
              shape="round"
              onClick={() =>
                // send related data to modal
                showModal(record.parameter, record.number, record.estimate)
              }
            >
              Edit{" "}
            </Button>
          </Space>
        </>
      ),
    },
  ];

  /**
   *  Show a summary table and the result
   */
  const showModal = (parameter, number, estimate) => {
    setIsModalVisible(true);
    // the estimate value will be edited
    setCurrentParameter(parameter);
    setCurrentNumber(number);
    setCurrentValue(estimate);
  };

  /**
   *  handle new input value
   */
  const handleOk = () => {
    // get current input value
    let newValue = newValueRef.current.value;
    // verify
    if (rx_live.test(newValue)) {
      // send data to DB then update the target value
      updateEstimateData(newValue, currentParameter, currentNumber);

      newValueRef.current.value = null;
      setIsModalVisible(false);
    } else {
      message.error("Please enter a number");
    }
  };

  // handle the modal of new input value
  const handleCancel = () => {
    setIsModalVisible(false);
    newValueRef.current.value = null;
  };

  // the user interface for editing value
  const editValuePanel = (
    <>
      <div style={{ marginBottom: "10px" }}>
        <b>Current Parameter name: </b>
        {currentParameter} <br />
        <b>Current number: </b>
        {currentNumber}
        <br />
        <b>Current estimate value: </b>
        {currentValue}
      </div>
      <input
        ref={newValueRef}
        style={{ width: "50%" }}
        placeholder="New Value"
        autoComplete="off"
      />
    </>
  );

  return (
    <div>
      <Tabs tabPosition="left">
        {tabs.map((tab, index) => (
          <TabPane tab={tab.name} key={index}>
            <Table
              bordered
              className={classes.table_panel}
              dataSource={tab.content}
              columns={columns}
              pagination={false}
            />
          </TabPane>
        ))}
      </Tabs>
      {/* A modal to edit the value or change the password */}
      <Modal
        title="Edit estimate value"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {editValuePanel}
      </Modal>
    </div>
  );
};

export default observer(ParameterPanel);
