import { Form, Input, Button } from "antd";
import { useStores } from "../../../store/StoresIndex";

const ChangePwdPanel = () => {
  const { UserStore } = useStores();
  const { changePwd } = UserStore;
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 8,
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      span: 8,
      offset: 8,
    },
  };

  const onFinish = (values) => {
    // console.log("Received values of form: ", values);
    changePwd(values.confirm);
    // clear the form
    form.resetFields();
  };

  return (
    <div>
      <Form
        {...formItemLayout}
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        style={{ marginTop: "5%" }}
      >
        <Form.Item
          name="password"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            {
              min: 6,
              message: "The password needs at least 6 characters!",
            },
          ]}
          hasFeedback
        >
          <Input.Password autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm New Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password autoComplete="off" />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePwdPanel;
