import "./App.css";
import Calculator from "./component/calculator/Calculator";
import Dashboard from "./component/dashboard/Dashboard";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./component/login/Login";
import { observer } from "mobx-react";
import Results from "./component/result/Results";
import { useStores } from "./store/StoresIndex";
import { useEffect } from "react";
import About from "./component/about/About";
function App() {
  const { RegressionStore, UserStore } = useStores();
  const { getDataFromDB } = RegressionStore;
  const { userEmail } = UserStore;

  useEffect(() => {
    getDataFromDB();
  });

  return (
    <div className="App">
      <Routes>
        {userEmail !== null ? (
          <Route path="/dashboard/*" element={<Dashboard />} />
        ) : (
          <Route path="/dashboard/*" element={<Navigate to="/login" />} />
        )}
        <Route path="/login" element={<Login />} />
        <Route path="/results" element={<Results />} />
        <Route path="/about" element={<About />} />
        <Route exact path="/" element={<Calculator />} />
      </Routes>
    </div>
  );
}

export default observer(App);
