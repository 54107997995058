import React from "react";
import { Menu, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "../../store/StoresIndex";

const NavigationItems = () => {
  const { UserStore } = useStores();
  const { logout } = UserStore;
  const navigate = useNavigate();
  const handleClick = () => {};

  //set login state to false
  const logOut = () => {
    logout();
    navigate("/");
  };
  return (
    <Menu
      onClick={handleClick}
      // selectedKeys={[current]}
      mode="horizontal"
      style={{
        float: "right",
        fontWeight: "500",
        fontSize: "14px",
        minWidth: "100%",
        paddingRight: "4%",
        marginTop: "5px",
        justifyContent: "end",
      }}
    >
      <Menu.Item key="edit">
        <Link to="/dashboard/edit-parameters" id="myLink">
          Edit Parameters
        </Link>
      </Menu.Item>
      <Menu.Item key="check">
        <Link to="/dashboard/check-data" id="myLink">
          Check Data
        </Link>
      </Menu.Item>
      <Menu.Item key="password">
        <Link to="/dashboard/change-password" id="myLink">
          Change Password
        </Link>
      </Menu.Item>
      <Menu.Item key="about">
        <Button
          type="primary"
          shape="round"
          style={{ paddingLeft: "20px" }}
          onClick={logOut}
        >
          LOG OUT
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default NavigationItems;
